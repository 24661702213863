import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addData } from "./../../utils/api/api";
import { API_ENDPOINTS } from "../../utils/api/endpoints";
import {
  ISECTION_WITH_TABLES,
  ITable,
} from "../../utils/interfaces/entities/table.interface";
import {
  getAllAvailabeTablesByRestaurant,
  getSectionsByRestaurant,
} from "../../services/tables";

const {
  GET_SECTIONS_BY_RESTAURANT,
  GET_TABLES_BY_RESTAURANT,
  GET_AVAILABLE_TABLES_BY_RESTAURANT,
} = API_ENDPOINTS;


export const getTablesBySection = createAsyncThunk(
  GET_SECTIONS_BY_RESTAURANT,
  async (id: string, thunkAPI) => {
    try {
      const result = await getSectionsByRestaurant(id);
      console.log(result, "result");
      if (result.status === "success") {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue({
          message: "Unable to login",
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: "Unable to Fetch Sections",
      });
    }
  }
);

export const getAvailableTablesByRestuarant = createAsyncThunk(
  GET_AVAILABLE_TABLES_BY_RESTAURANT,
  async (id: string, thunkAPI) => {
    try {
      const result = await getAllAvailabeTablesByRestaurant(id);
      console.log(result, "result");
      if (result.status === "success") {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue({
          message: "Unable to login",
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: "Unable to Fetch Sections",
      });
    }
  }
);

export interface IInitialState {
  tablesBySection: ISECTION_WITH_TABLES[] | [];
  tablesList: ITable[] | [];
  availableTables: ITable[] | [];
  selectedSection: string;
  error: {
    message: string;
    status: string;
  };
  loading: boolean;
}

const initialState: IInitialState = {
  tablesBySection: [],
  tablesList: [],
  selectedSection: "",
  availableTables: [],
  error: {
    message: "",
    status: "idle",
  },
  loading: false,
};

const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
      if (state.tablesBySection.length > 0) {
        let section = state.tablesBySection.find(
          (section) => section._id === action.payload
        );
        state.tablesList = section ? section.tables : [];
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getTablesBySection.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTablesBySection.fulfilled, (state, action) => {
      state.tablesBySection = action.payload;
      state.loading = false;
    });
    builder.addCase(getTablesBySection.rejected, (state, action) => {
      const { message } = (action.payload as unknown) as {
        message: string;
      };

      state.error = {
        message:
          message && message.length > 0 ? message : "Unable to Fetch Sections",
        status: "error",
      };
      state.loading = false;
    });
    builder.addCase(getAvailableTablesByRestuarant.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAvailableTablesByRestuarant.fulfilled, (state, action) => {
      state.availableTables = action.payload;
      state.loading = false;
    });
    builder.addCase(getAvailableTablesByRestuarant.rejected, (state, action) => {
      const { message } = (action.payload as unknown) as {
        message: string;
      };

      state.error = {
        message:
          message && message.length > 0 ? message : "Unable to Fetch Tables",
        status: "error",
      };
      state.loading = false;
    });
  },
});

export const { setSelectedSection } = tablesSlice.actions;

export default tablesSlice.reducer;

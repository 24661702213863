import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import image from "../../static/img/temp/restaurant.jpg";
import { Link } from "@mui/material";
import { paths } from "../../routes/paths";

export default function MediaCard() {
  return (
    <>
      <Card
        sx={{
          maxWidth: 1000,
          display: "flex",
          flexDirection: "row",
          boxShadow: 0,
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            maxWidth: 400,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CardMedia
            sx={{ height: 200, width: 200, borderRadius: 0.3 }}
            image={image}
            title="green iguana"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent>
            <Link
              variant="h5"
              color="textPrimary"
              href={paths.restaurant_view}
              underline="none"
              gutterBottom
            >
              Benihana - Bethesda, MD
            </Link>
            <Typography
              variant="body1"
              color="text.secondary"
              fontWeight="bold"
            >
              • Asian • Bethesda / Chevy Chase
            </Typography>

            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TrendingUpRoundedIcon
                sx={{
                  mr: 1,
                }}
              />
              Booked 59 times today
            </Typography>
          </CardContent>
          <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
            <Button variant="contained">7:30 PM</Button>
            <Button variant="contained">6:30 PM</Button>
            <Button variant="contained">4:50 PM</Button>
            <Button variant="contained">3:00 PM</Button>
            <Button variant="contained">8:30 PM</Button>
            <Button variant="contained">9:00 PM</Button>
          </Stack>
        </Box>
      </Card>
    </>
  );
}

import React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import MuiDrawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItemCard from "./MenuItemCard";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import { IItem, menu } from "../../utils/constants/temp";

export const drawerWidth = 50;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth + "%",
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth + "%",
  zIndex: theme.zIndex.appBar + 1,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MENU_ITEM: IItem = {
  name: "Chicken",
  description: "Chicken with rice",
  price: 10,
  image: "https://picsum.photos/300",
  options: [
    {
      name: "Rice",
      price: 10,
    },
  ],
};

interface INewMenuItemFormSideBarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const NewMenuItemForm: React.FC<INewMenuItemFormSideBarProps> = (props) => {
  const { open, handleDrawerClose } = props;

  return (
    <Drawer variant="permanent" open={open} anchor="right">
      <Box
        sx={{
          m: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              mr: 1,
            }}
          >
            <CloseRoundedIcon fontSize="large" />
          </IconButton>
          New Menu Item
        </Typography>

        <Box
          sx={{
            m: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack justifyContent="flex-end">
                <Autocomplete
                  size="small"
                  id="menu-section-list"
                  fullWidth
                  options={menu}
                  getOptionLabel={(menuSection) => menuSection.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Menu Section" />
                  )}
                />
                <TextField size="small" name="name" label="Name" />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField size="small" name="price" label="Price" />
                  <input
                    type="file"
                    id="fileUploadButton"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    // onChange={onFileChange}
                  />
                  <label htmlFor={"fileUploadButton"}>
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      startIcon={
                        <SvgIcon fontSize="small">
                          <AddPhotoAlternateRoundedIcon />
                        </SvgIcon>
                      }
                    >
                      Upload
                    </Button>
                  </label>
                </Box>
                <TextField
                  size="small"
                  multiline
                  rows={3}
                  maxRows={3}
                  name="description"
                  label="Description"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MenuItemCard item={MENU_ITEM} />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            m: 2,
          }}
        >
          <Button variant="text">Close</Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              mx: 1,
            }}
          >
            Save
          </Button>
          <Button variant="contained" color="primary">
            Save and Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NewMenuItemForm;

import APPETIZER_ICON from "../../static/img/temp/bread.png";
import PIZZA_ICON from "../../static/img/temp/pizza.png";
import BEVERAGE_ICON from "../../static/img/temp/coke.png";
import DESSERT_ICON from "../../static/img/temp/cake.png";

export const sectionsWithTable = [
  {
    name: "Outdoor",
    status: "available",
    tables: [
      { name: "T1", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T2", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T3", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T4", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T5", min_capacity: 2, max_capacity: 4, status: "available" },
    ],
  },
  {
    name: "Indoor",
    status: "available",
    tables: [
      { name: "T1", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T2", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T3", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T4", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T5", min_capacity: 2, max_capacity: 4, status: "available" },
    ],
  },
  {
    name: "Private",
    status: "available",
    tables: [
      { name: "T1", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T2", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T3", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T4", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T5", min_capacity: 2, max_capacity: 4, status: "available" },
    ],
  },
  {
    name: "Family",
    status: "available",
    tables: [
      { name: "T1", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T2", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T3", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T4", min_capacity: 2, max_capacity: 4, status: "available" },
      { name: "T5", min_capacity: 2, max_capacity: 4, status: "available" },
    ],
  },
];

export const tables = [
  { name: "T1", min_capacity: 2, max_capacity: 4, status: "available" },
  { name: "T2", min_capacity: 2, max_capacity: 4, status: "available" },
  { name: "T3", min_capacity: 2, max_capacity: 4, status: "available" },
  { name: "T4", min_capacity: 2, max_capacity: 4, status: "available" },
  { name: "T5", min_capacity: 2, max_capacity: 4, status: "available" },
];

export const personList = [
  {
    label: "2 People",
  },
  {
    label: "3 People",
  },
  {
    label: "4 People",
  },
  {
    label: "5 People",
  },
  {
    label: "6 People",
  },
  {
    label: "7 People",
  },
  {
    label: "8 People",
  },
  {
    label: "9 People",
  },
];
export const timeSlot = [
  { label: "10:00 AM" },
  { label: "10:30 AM" },
  { label: "11:00 AM" },
  { label: "11:30 AM" },
  { label: "12:00 PM" },
  { label: "12:30 PM" },
  { label: "01:00 PM" },
  { label: "01:30 PM" },
  { label: "02:00 PM" },
  { label: "02:30 PM" },
  { label: "03:00 PM" },
  { label: "03:30 PM" },
  { label: "04:00 PM" },
];

export const Filter = [
  { label: "Featured" },
  { label: "Highest Rated" },
  { label: "Distance" },
  { label: "Newest" },
];
export interface IOption {
  name: string;
  price: number;
}

export interface IItem {
  name: string;
  price: number;
  description: string;
  image: string;
  options: IOption[];
}
export interface ITable {
  name: string;
  min_capacity: number;
  max_capacity: number;
  status: string;
  images: File[];
  description: string;
  online_booking: boolean;
}

export interface IMenu {
  name: string;
  icon: string;
  items: IItem[];
}

export const menu = [
  {
    name: "Appetizers",
    icon: APPETIZER_ICON,
    items: [
      {
        name: "Fries",
        price: 5,
        description: "Fries with cheese",
        image: APPETIZER_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Onion Rings",
        price: 5,
        description: "Onion Rings with cheese",
        image: APPETIZER_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Chicken Wings",
        price: 5,
        description: "Chicken Wings with cheese",
        image: APPETIZER_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Chicken Nuggets",
        price: 5,
        description: "Chicken Nuggets with cheese",
        image: APPETIZER_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
    ],
  },
  {
    name: "Main Course",
    icon: PIZZA_ICON,
    items: [
      {
        name: "Burger",
        price: 5,
        description: "Burger with cheese",
        image: PIZZA_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Pizza",
        price: 5,
        description: "Pizza with cheese",
        image: PIZZA_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Pasta",
        price: 5,
        description: "Pasta with cheese",
        image: PIZZA_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
    ],
  },
  {
    name: "Desserts",
    icon: DESSERT_ICON,
    items: [
      {
        name: "Ice Cream",
        price: 5,
        description: "Ice Cream with cheese",
        image: DESSERT_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Cake",
        price: 5,
        description: "Cake with cheese",
        image: DESSERT_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Brownie",
        price: 5,
        description: "Brownie with cheese",
        image: DESSERT_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
    ],
  },
  {
    name: "Drinks",
    icon: BEVERAGE_ICON,
    items: [
      {
        name: "Coke",
        price: 5,
        description: "Coke with cheese",

        image: BEVERAGE_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Fanta",
        price: 5,
        description: "Fanta with cheese",
        image: BEVERAGE_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
      {
        name: "Sprite",
        price: 5,
        description: "Sprite with cheese",
        image: BEVERAGE_ICON,
        options: [
          { name: "Small", price: 0 },
          { name: "Medium", price: 2 },
          { name: "Large", price: 4 },
        ],
      },
    ],
  },
];

export const ORDER_CART = [
  {
    name: "Burger",
    price: 5,
    quantity: 1,
    additional_notes: "No cheese",
    image: PIZZA_ICON,
  },
  {
    name: "Pizza",
    price: 5,
    quantity: 1,
    additional_notes: "No cheese",
    image: PIZZA_ICON,
  },
]


export const AVAILABLE_FILTERS = [
  {
    name: "experiences",
    title: "Experiences",
    options: ["Discount Offers", "Birthday Party", "Family Dinner", "Date Night"]
  },
  {
    name: "cuisines",
    title: "Cuisines",
    options: ["Pakistani", "American", "Chinese", "Indian", "Italian", "Japanese", "Mexican", "Thai"]
  },
  {
    name: "seating",
    title: "Seating Options",
    options: ["Indoor", "Outdoor", "Bar Seating", "Private Dining", "Cafe Seating", "Dining Table"]
  },
  {
    name: "amenities",
    title: "Amenities",
    options: ["Wifi", "Parking", "Smoking Area", "Takeaway", "Delivery", "Reservations", "Credit Card"]
  }, 
  {
    name: "ambience",
    title: "Ambience",
    options: ["Casual", "Romantic", "Family Friendly", "Business", "Quiet", "Trendy", "Classy", "Hipster", "Divey", "Intimate", "Trendy"]
  },
];

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TablesList from "../../components/tables/TablesList";
import SectionsList from "../../components/tables/SectionsList";
import { AppDispatch, RootState } from "../../store";
import { getTablesBySection } from "../../store/reducers/tablesSlice";

const ManageTables: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const businessUser = useSelector((state: RootState) => state.auth.user);

  const getAllTablesBySection = (id: string) => {
    dispatch(getTablesBySection(id));
  };

  useEffect(() => {
    if (businessUser && businessUser.id) {
      getAllTablesBySection(businessUser.id);
    }
  }, [businessUser]);

  return (
    <Grid container>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SectionsList />
      </Grid>
      <Grid
        item
        xs={9}
        sx={{
          borderLeft: "1px solid #e0e0e0",
        }}
      >
        <TablesList />
      </Grid>
    </Grid>
  );
};

export default ManageTables;

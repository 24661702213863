import { addData, getAllData } from "../utils/api/api";
import { API_ENDPOINTS } from "../utils/api/endpoints";

const { GET_GUEST_BY_RESTAURANT } = API_ENDPOINTS;

export const getGuestsByRestuarants = async (id: string) => {
  try {
    const result = await getAllData(`${GET_GUEST_BY_RESTAURANT}/${id}`);
    if (result.status === 200) {
      return {
        status: "success",
        data: result.data.guests,
        message: "Guests fetched successfully",
      };
    } else {
      return {
        status: "error",
        message: "Unable to fetch guests",
      };
    }
  } catch (error) {
    return {
      status: "error",
      message: "Unable to fetch guests",
    };
  }
};

import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { getTimeFromMilliseconds } from "../../utils/functions/dateTime";

const ReservationListItem: React.FC = () => {
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        width: "100%",
      }}
    >
      <ListItemAvatar>
        <Box
          sx={{
            mx: 1,
            backgroundColor: "primary.dark",
            color: "white",
            "& > *": {
              px: 1,
              py: 0.5,
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
            }}
          >
            {getTimeFromMilliseconds(new Date().getTime())}
          </Typography>
        </Box>
      </ListItemAvatar>
      <ListItemText
        primary="Oui Oui"
        secondaryTypographyProps={{
          component: "div",
        }}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              Sandra Adams
            </Typography>
            <Box
              sx={{
                "& > *": {
                  m: 0.5,
                },
              }}
            >
              <Chip
                icon={<PersonOutlineRoundedIcon />}
                size="small"
                label="2 Guests"
                variant="outlined"
              />
              <Chip size="small" label="Dine In" variant="outlined" />
            </Box>
          </React.Fragment>
        }
      />
      <Box
        sx={{
          m: 1,
          backgroundColor: "primary.dark",
          color: "white",
          "& > *": {
            px: 2,
            py: 0.5,
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
          }}
        >
          T1
        </Typography>
      </Box>
    </ListItem>
  );
};

export default ReservationListItem;

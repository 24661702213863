import React from "react";
import Home from "../pages/home";
import Login from "../pages/login";
import Signup from "../pages/signup";
import Menu from "../pages/menu";
import ManageTables from "../pages/tables";
import ManageReservation from "../pages/manage-reservations";
import FindRestaurant from "../pages/find-restaurant";
import RestaurantView from "../pages/restaurant-view";
import NewOrder from "../pages/new-order";
import Guests from "../pages/guests";

export const paths = {
  home: "/",
  dashboard: "/dashboard",
  login: "/login",
  signup: "/signup",
  manage_reservations: "/manage-reservations",
  menu: "/menu",
  orders: "/orders",
  new_order: "/new-order",
  find_rastaurant: "/find-restaurant",
  restaurant_view: "/restaurant-view",
  tables: "/tables",
  guests: "/guests",
  error: "*",
};

export const routes = {
  home: "/",
  dashboard: "/dashboard",
  login: "/login",
  signup: "/signup",
  manage_reservations: "/manage-reservations",
  menu: "/menu",
  orders: "/orders",
  new_order: "/new-order",
  find_rastaurant: "/find-restaurant",
  restaurant_view: "/restaurant-view",
  tables: "/tables",
  guests: "/guests",
  error: "*",
};

export const publicRoutes = {};

export const restaurantPrivateRoutes = {
  [paths.home]: {
    name: "Home",
    path: routes.home,
    component: <Home />,
  },
  [paths.manage_reservations]: {
    name: "Manage Reservations",
    path: routes.manage_reservations,
    component: <ManageReservation />,
  },
  [paths.tables]: {
    name: "Manage Tables",
    path: routes.tables,
    component: <ManageTables />,
  },
  [paths.menu]: {
    name: "Menu",
    path: routes.menu,
    component: <Menu />,
  },
  [paths.new_order]: {
    name: "New Order",
    path: routes.new_order,
    component: <NewOrder />,
  },
  [paths.guests]: {
    name: "Guests",
    path: routes.guests,
    component: <Guests />,
  },
};

export const customerPrivateRoutes = {
  [paths.find_rastaurant]: {
    name: "Find Restaurant",
    path: routes.find_rastaurant,
    component: <FindRestaurant />,
  },
  [paths.restaurant_view]: {
    name: "Restaurant View",
    path: routes.restaurant_view,
    component: <RestaurantView />,
  },    
};

export const onlyPublicRoutes = {
  [paths.login]: {
    name: "Login",
    path: routes.login,
    component: <Login />,
  },
  [paths.signup]: {
    name: "Signup",
    path: routes.signup,
    component: <Signup />,
  },
};

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import RestaurantIcon from "@mui/icons-material/Restaurant";
export default function RestaurantDetail() {
  return (
    <>
      <Box sx={{ my: 4, mx: 2 }}>
        <Typography variant="h3" component="h1">
          Pho Viet USA
        </Typography>
        <Divider sx={{ marginTop: 4 }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          py: 0,
          marginTop: -2,
          mx: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <LocalAtmIcon />
          <Typography
            variant="body1"
            fontWeight={"bold"}
            sx={{ marginLeft: 1 }}
          >
            $30 and under
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mx: 1,
          }}
        >
          <RestaurantIcon />
          <Typography
            variant="body1"
            fontWeight={"bold"}
            sx={{ marginLeft: 1 }}
          >
            Asian
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", my: 1, mx: 2 }}>
        <Typography variant="body1" fontWeight={"bold"} sx={{ my: 1 }}>
          Top Tags:
        </Typography>
        <Box sx={{ mx: 2 }}>
          <Button variant="outlined" sx={{ mx: 1 }}>
            Great For Happy Hour
          </Button>
          <Button variant="outlined">Healthy</Button>
          <Button variant="outlined" sx={{ mx: 1 }}>
            Neighborhood Gem
          </Button>
        </Box>
      </Box>
      <Box sx={{ my: 2, mx: 2 }}>
        <Typography variant="body1">
          We bring the most authentic Vietnamese cuisine flavor prepared by our
          most dedicated Vietnamese chefs. Our staffs are all young and
          passionate making customers feel the welcomed and cozy. Come join us
          in enjoying the quintessentials of Vietnamese dishes--from our
          signature banh mi to our best-selling pho and more! Everything fresh
          and made to order. Vegetarian options available!
        </Typography>
      </Box>
    </>
  );
}

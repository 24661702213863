import React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { drawerWidth } from "../SideBar/RestaurantSideBar";
import ProfileMenu from "../../../components/layout/ProfileMenu";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface IHeaderProps {
  isLoggedIn: boolean;
  handleRestaurantDrawerOpen: () => void;
  handleRestaurantDrawerClose: () => void;
  restaurantDrawerOpen: boolean;
}

const Header: React.FC<IHeaderProps> = (props) => {
  // TODO: update this logic when imlementing user authentication and remove all the hardcoded data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    isLoggedIn,
    handleRestaurantDrawerOpen,
    handleRestaurantDrawerClose,
    restaurantDrawerOpen,
  } = props;

  const toggleSideBar = () => {
    if (restaurantDrawerOpen) {
      handleRestaurantDrawerClose();
    } else {
      handleRestaurantDrawerOpen();
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: "none",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSideBar}
          edge="start"
          sx={{
            marginRight: 5,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          {/* // TODO: Add LOGO HERE  */}
          reserve
        </Typography>
        {isLoggedIn && (
          <>
            {/* <SearchInput /> */}

            {/* // TODO: Update notifications icon BUTTON later */}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <ProfileMenu />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ResponsiveDialog from "../../common/Dialog/ResponsiveDialog";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { fieldNames } from "../../../utils/constants/formsConstants";

const AddSectionDialog: React.FC = () => {
  const [openSectionModal, setOpenSectionModal] = React.useState<boolean>(
    false
  );

  const handleNewSectionClickOpen = () => {
    setOpenSectionModal(true);
  };

  const handleNewSectionClose = () => {
    setOpenSectionModal(false);
  };

  return (
    <>
      <Button
        color="primary"
        startIcon={<AddRoundedIcon />}
        onClick={handleNewSectionClickOpen}
      >
        New Section
      </Button>

      <ResponsiveDialog
        open={openSectionModal}
        title="New Section"
        handleClose={handleNewSectionClose}
        actions={
          <>
            <Button onClick={handleNewSectionClose}>Cancel</Button>
            <Button onClick={handleNewSectionClose}>Add Section</Button>
          </>
        }
      >
        <Box
          sx={{
            minWidth: "100%",
            mb: 1,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              label="Section Name"
              placeholder="e.g. Main Room"
              name={fieldNames.section_name}
            />
          </Stack>
        </Box>
      </ResponsiveDialog>
    </>
  );
};

export default AddSectionDialog;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageNotSupportedRoundedIcon from "@mui/icons-material/ImageNotSupportedRounded";

const NoImage: React.FC = () => {
  return (
    <Box
      sx={{
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        w: "100%",
        h: "100%",
      }}
    >
      <ImageNotSupportedRoundedIcon
        sx={{
          fontSize: "4rem",
        }}
      />
      <Typography>No Image Available</Typography>
    </Box>
  );
};

export default NoImage;

import React from "react";
import Grid from "@mui/material/Grid";
import TablesList from "../../components/manage-reservations/TablesList";
import ReservationsList from "../../components/manage-reservations/ReservationsList";

const ManageReservation: React.FC = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ReservationsList />
      </Grid>
      <Grid item xs={8} sx={{
        borderLeft: "1px solid #e0e0e0",
      }}>
        <TablesList />
      </Grid>
    </Grid>
  );
};

export default ManageReservation;

import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import InputFieldWithIncDec from "../../common/InputFields/InputFieldWithIncDec";

const buttons = [
  <Button key="one" fullWidth>
    Phone
  </Button>,
  <Button
    key="two"
    fullWidth
    sx={{
      whiteSpace: "nowrap",
    }}
  >
    Walk-In
  </Button>,
];

const ReservationDetails: React.FC = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        Reservation Details
      </Typography>

      <Stack>
        <InputFieldWithIncDec
          title="Date"
          handleDecrement={() => {}}
          handleIncrement={() => {}}
        >
          <TextField
            id="outlined-basic"
            label="Date"
            variant="outlined"
            size="small"
          />
        </InputFieldWithIncDec>

        <InputFieldWithIncDec
          title="Time"
          handleDecrement={() => {}}
          handleIncrement={() => {}}
        >
          <TextField
            id="outlined-basic"
            label="Time"
            variant="outlined"
            size="small"
          />
        </InputFieldWithIncDec>

        <InputFieldWithIncDec
          title="Guests"
          handleDecrement={() => {}}
          handleIncrement={() => {}}
        >
          <TextField
            id="outlined-basic"
            label="Guests"
            variant="outlined"
            size="small"
          />
        </InputFieldWithIncDec>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            "& > *": {
              m: 1,
              fontWeight: "bold",
            },
          }}
        >
          <Typography variant="body1">Origin</Typography>
          <ButtonGroup size="small" aria-label="reservation origin">
            {buttons}
          </ButtonGroup>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            "& > *": {
              m: 1,
              fontWeight: "bold",
            },
          }}
        >
          <Typography variant="body1">Notes</Typography>
          <TextField
            id="reservation-notes"
            label="Notes"
            multiline
            rows={2}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default ReservationDetails;

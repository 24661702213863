import React from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import SectionListItem from "./SectionListItem";
import AddSectionDialog from "./AddSectionModal/AddSectionDialog";
import { RootState } from "../../store";

const SectionsList: React.FC = () => {
  const sectionsList = useSelector(
    (state: RootState) => state.tables.tablesBySection
  );

  const isLoading = useSelector((state: RootState) => state.tables.loading);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& > *": {
          m: 1,
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <List dense={true} sx={{ width: "100%", bgcolor: "background.paper" }}>
          {sectionsList.length > 0 &&
            sectionsList.map((section) => (
              <React.Fragment key={uuidv4()}>
                <SectionListItem {...section} />
                <Divider component="li" />
              </React.Fragment>
            ))}
        </List>
      </Box>
      <Box
        sx={{
          width: "100%",
          m: 1,
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 3,
            m: 1,
          }}
        >
          <AddSectionDialog />
        </Paper>
      </Box>
    </Box>
  );
};

export default SectionsList;

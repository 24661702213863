import React, { useEffect } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AppDispatch, RootState } from "../../store";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoginImage from "../../static/img/user_login.svg";
import { paths } from "../../routes/paths";
import {
  businessUserLogin,
  clearError,
  initialize,
  userLogin,
} from "../../store/reducers/authSlice";
import { setAccountType } from "../../store/reducers/accountTypeSlice";
import { fieldNames, messages } from "../../utils/constants/formsConstants";

interface IInitialState {
  email: string;
  password: string;
}

const initialState: IInitialState = {
  email: "",
  password: "",
};

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(messages.notValid)
    .required(messages.isRequired),
  password: Yup.string()
    .min(8, messages.password)
    .required(messages.isRequired),
});

const Login: React.FC = () => {
  const theme = useTheme();
  const accountType = useSelector(
    (state: RootState) => state.accountType.accountType
  );
  const error = useSelector((state: RootState) => state.auth.error);

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const userType = useSelector((state: RootState) => state.auth.userType);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleAccountType = () => {
    if (accountType === "restaurant") {
      dispatch(setAccountType("user"));
    } else {
      dispatch(setAccountType("restaurant"));
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      if (accountType === "user") {
        dispatch(userLogin(values));
      } else {
        dispatch(businessUserLogin(values));
      }
    },
  });

  useEffect(() => {
    dispatch(clearError());
    dispatch(initialize());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (userType === "user") {
        navigate(paths.find_rastaurant);
      } else {
        navigate(paths.manage_reservations);
      }
    }
  }, [isLoggedIn]);

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={0} md={4}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <img src={LoginImage} alt="Login" />
          </Box>

          <Typography
            variant="body1"
            color="white"
            gutterBottom
            sx={{
              margin: theme.spacing(3, 0),
            }}
          >
            You're signing into a {accountType} account.
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              marginTop: theme.spacing(2),
            }}
            onClick={handleAccountType}
          >
            Login As {accountType === "restaurant" ? "User" : "Restaurant"}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={0} md={8}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: theme.spacing(0, 6),
          }}
        >
          {error && error.status === "error" && (
            <Alert
              severity="error"
              sx={{
                marginBottom: theme.spacing(2),
              }}
            >
              {error.message}
            </Alert>
          )}
          <Typography variant="h4" component="h1">
            {accountType !== "restaurant" ? "User" : "Restaurant"} Login
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            gutterBottom
            sx={{
              marginBottom: theme.spacing(4),
            }}
          >
            Hey, Enter your account credientials to login. :)
          </Typography>

          <TextField
            label="Email"
            placeholder="e.g. johndoe@example.com"
            name={fieldNames.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            type="password"
            label="Password"
            placeholder="Please enter your password"
            name={fieldNames.password}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{
              marginTop: theme.spacing(2),
            }}
            onClick={() => formik.handleSubmit()}
          >
            Sign In
          </Button>

          <Typography
            variant="subtitle1"
            color="textSecondary"
            gutterBottom
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            Don't have an account?{" "}
            <Link onClick={() => navigate(paths.signup)}>Sign Up</Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;

import * as React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Paper from "@mui/material/Paper";
import { IMenu, menu } from "../../utils/constants/temp";
import MenuItemsList from "../../components/menu/MenuItemsList";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export interface IMenuTabsProps {
  orientation?: "vertical" | "horizontal";
  mode?: "edit" | "view" | "order";
}

const MenuTabs: React.FC<IMenuTabsProps> = (props) => {
  const { orientation, mode } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        m: 3,
      }}
    >
      <Tabs
        orientation={orientation}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {menu.map((menuSection: IMenu, index) => (
          <Tab
          key={index}
            label={menuSection.name}
            icon={
              <img
                src={menuSection.icon}
                alt={menuSection.name}
                height="50px"
              />
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {menu.map((menuSection: IMenu, index) => (
        <MenuItemsList
          key={index}
          value={value}
          index={index}
          mode={mode}
          orientation={orientation}
          sectionName={menuSection.name}
          menuItems={menuSection.items}
        />
      ))}
    </Paper>
  );
};

MenuTabs.defaultProps = {
  orientation: "vertical",
  mode: "view",
};

export default MenuTabs;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddRounded from "@mui/icons-material/AddRounded";
import RemoveRounded from "@mui/icons-material/RemoveRounded";
import { addFilter, removeFilter } from "../../../store/reducers/filtersSlice";
import { AVAILABLE_FILTERS } from "../../../utils/constants/temp";

interface IFilterCheckboxesProps {
  filter: {
    name: string;
    title: string;
    options: string[];
  };
}

const FilterCheckboxes: React.FC<IFilterCheckboxesProps> = ({ filter }) => {
  const { name, options } = filter;

  const [showMore, setShowMore] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);

  const dispatch = useDispatch();

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, e.target.value]);
    } else {
      setChecked((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  useEffect(() => {
    if (checked.length > 0) {
      dispatch(addFilter({ key: name, value: checked }));
    } else {
      dispatch(removeFilter(name));
    }
  }, [checked]);

  return (
    <>
      <FormGroup>
        {options.slice(0, showMore ? options.length : 5).map((option) => (
          <FormControlLabel
            key={uuidv4()}
            control={
              <Checkbox
                name={name}
                size="small"
                value={option}
                checked={checked.includes(option)}
                onChange={(e) => handleCheckbox(e)}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>

      {options.length > 5 && (
        <Button
          variant="text"
          startIcon={showMore ? <RemoveRounded /> : <AddRounded />}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Show Less" : "Show More"}
        </Button>
      )}
    </>
  );
};

const VerticalFilters: React.FC = () => {
  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      {AVAILABLE_FILTERS.map((filter) => (
        <Box
          key={uuidv4()}
          sx={{
            py: 2,
            borderBottom: "1px solid",
            borderColor: "divider",
            maxWidth: "350px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            fontWeight={500}
          >
            {filter.title}
          </Typography>

          <FilterCheckboxes filter={filter} />
        </Box>
      ))}
    </Stack>
  );
};

export default VerticalFilters;

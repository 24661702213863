import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import RestaurantDetail from "./RestaurantDetail";
import RestaurantMenu from "./RestaurantMenu";
import MakeReservation from "./MakeReservation";
export default function RestaurantBar() {
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Box
            sx={{
              width: "100%",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              marginTop: -6,
              backgroundColor: "white",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <Tab value="one" label="Overview" />
              <Tab value="two" label="Tables" />
              <Tab value="three" label="Menu" />
            </Tabs>
          </Box>
          <RestaurantDetail />
          <RestaurantMenu />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              width: "100%",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              borderBottomRightRadius: 5,
              borderBottomLeftRadius: 5,
              marginTop: -6,
              backgroundColor: "white",
              mx: 4,
              boxShadow: 1,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={"bold"}
              sx={{ textAlign: "center", paddingTop: 1 }}
            >
              Make a Reservation
            </Typography>
            <MakeReservation />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { IItem } from "../../utils/constants/temp";
import { IMenuTabsProps } from "./MenuTabs";
import AddItemToCartDialog from "../new-order/AddItemToCartModal/AddItemToCartDialog";

interface IMenuItemCardProps {
  MenuTabsProps?: IMenuTabsProps;
  item: IItem;
}

const MenuItemCard: React.FC<IMenuItemCardProps> = (props) => {
  const { MenuTabsProps, item } = props;
  const mode = MenuTabsProps ? MenuTabsProps.mode : "view";
  const { name, price, description, image } = item;

  return (
    <Card
      variant="outlined"
      sx={{
        position: "relative",
      }}
    >
      {mode !== "view" && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            m: 1,
          }}
        >
          {mode === "edit" && (
            <IconButton>
              <EditRoundedIcon />
            </IconButton>
          )}
          {mode === "order" && <AddItemToCartDialog item={item} />}
        </Box>
      )}
      <Box
        sx={{
          background: "white",
          py: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={image} alt={name} height="140px" />
      </Box>
      <CardContent>
        <Typography gutterBottom variant="h6">
          {name}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{
            fontWeight: "bold",
          }}
        >
          Rs. {price}
        </Typography>
        {mode !== "order" && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MenuItemCard;

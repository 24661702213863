import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import InputFieldWithIncDec from "../../common/InputFields/InputFieldWithIncDec";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { sectionsWithTable } from "../../../utils/constants/temp";
import { AppDispatch, RootState } from "../../../store";
import { getAvailableTablesByRestuarant } from "../../../store/reducers/tablesSlice";

const TableDetails: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const availableTables = useSelector(
    (state: RootState) => state.tables.availableTables
  );

  const getAvailableTables = () => {
    dispatch(getAvailableTablesByRestuarant("6353ce6c92cee94ca51e4b23"));
  };

  useEffect(() => {
    getAvailableTables();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        Table Details
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& > *": {
            m: 1,
          },
        }}
      >
        <Autocomplete
          size="small"
          id="tables-list"
          fullWidth
          options={availableTables}
          getOptionLabel={(table) => "T" + table.table_number}
          renderInput={(params) => <TextField {...params} label="Tables" />}
        />
      </Box>

      <Stack>
        <List
          dense
          sx={{
            m: 1,
            overflowY: "scroll",
            height: "40vh",
          }}
        >
          {availableTables.map((table) => {
            return (
              <ListItem
                key={uuidv4()}
                secondaryAction={
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      m: 1,
                    }}
                  >
                    <PersonOutlineRoundedIcon fontSize="small" />
                    {table.min_capacity} - {table.table_capacity}
                  </Typography>
                }
              >
                <ListItemIcon>
                  <Box
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      border: 1,
                      borderColor: "primary.main",
                      borderRadius: 3,
                      "& > *": {
                        px: 2,
                        py: 0.5,
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      T{table.table_number}
                    </Typography>
                  </Box>
                </ListItemIcon>
                <ListItemText primary="Main Room" />
              </ListItem>
            );
          })}
        </List>
      </Stack>
    </Box>
  );
};

export default TableDetails;

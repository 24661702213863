import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

interface IInputFieldWithIncDecProps {
  title?: string;
  children: React.ReactNode;
  handleDecrement: () => void;
  handleIncrement: () => void;
}

const InputFieldWithIncDec: React.FC<IInputFieldWithIncDecProps> = (props) => {
  const { title, children, handleDecrement, handleIncrement } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& > *": {
          m: 1,
          fontWeight: "bold",
        },
      }}
    >
      {title && (
        <Typography
          sx={{
            mr: 1,
            fontWeight: "bold",
          }}
          variant="body1"
        >
          {title}
        </Typography>
      )}
      <IconButton
        size="small"
        aria-label={`decrement ${title}`}
        onClick={() => handleDecrement()}
      >
        <RemoveRoundedIcon fontSize="small" />
      </IconButton>
      {children}
      <IconButton
        size="small"
        aria-label={`increment ${title}`}
        onClick={() => handleIncrement()}
      >
        <AddRoundedIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default InputFieldWithIncDec;

import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Header from "./Header";

interface ICustomerLayoutProps {}

const CustomerLayout: React.FC<ICustomerLayoutProps> = () => {


  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Header />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default CustomerLayout;
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import CloseRounded from "@mui/icons-material/CloseRounded";

interface IResponsiveDialogProps extends DialogProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  actions?: React.ReactNode;
}

const ResponsiveDialog: React.FC<IResponsiveDialogProps> = (props) => {
  const { open, title, children, actions, handleClose } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen={fullScreen}
      aria-labelledby={`${title}-dialog-title`}
      aria-describedby={`${title}-dialog-description`}
      onClick={event => event.stopPropagation()}
      onMouseDown={event => event.stopPropagation()}
    >
      <DialogTitle
        id={`${title}-dialog-title`}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default ResponsiveDialog;

import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import cartSlice from './cartSlice';
import tablesSlice from './tablesSlice';
import filtersSlice from './filtersSlice';
import accountTypeSlice from './accountTypeSlice';
import responseMessageSlice from './responseMessageSlice';

const rootReducer = combineReducers({
  cart: cartSlice,
  auth: authSlice,
  tables: tablesSlice,
  filters: filtersSlice,
  accountType: accountTypeSlice,
  responseMessage: responseMessageSlice,
});

export default rootReducer;

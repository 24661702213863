import { createSlice } from "@reduxjs/toolkit";

export interface IInitialState {
  accountType: "restaurant" | "user";
}

const initialState: IInitialState = {
  accountType: "user",
};

const accountTypeSlice = createSlice({
  name: "accountType",
  initialState,
  reducers: {
    setAccountType: (state, action) => {
      state.accountType = action.payload;
    },
  },
});

export const { setAccountType } = accountTypeSlice.actions;

export default accountTypeSlice.reducer;

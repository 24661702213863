import { createTheme, useTheme } from "@mui/material/styles";
import { lime, yellow, indigo } from "@mui/material/colors";

const theme = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: { 
          cursor: "pointer",
          textDecoration: "none",
        }
      }
    },
    MuiDivider: {
      defaultProps: {
        orientation: "horizontal",
        variant: "fullWidth",
      },
      styleOverrides: {
        root: {
          width: "100%",
        }
      }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "none",
                fontWeight: 600,
            },
            contained: {
                boxShadow: "none",
                '&:hover': {
                    boxShadow: "none",
                },
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            // root: {
            //     fontSize: '1.25rem',
            //     fontWeight: 600,
            // }
        }
    },
    MuiTextField: {
      styleOverrides: {
        // root:{
        //     "& .MuiOutlinedInput-root": {
        //         fontSize: "1.25rem",
        //         fontWeight: 500,
        //     }
        // },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0.5rem 0",
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#38CB89',
      light: '#DEEEE8',
      dark: '#278e5f',
      contrastText: "#fff",
    },
    secondary: {
        main: yellow[700],
        light: yellow[300],
        dark: yellow[800],
        contrastText: "#fff",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "'Urbanist', sans-serif",
    htmlFontSize: 16,
    h1: {
      fontWeight: 900,
    },
    h2: {
      fontWeight: 900,
    },
    h3: {
      fontWeight: 900,
    },
    h4: {
      fontWeight: 900,
    },
    h5: {
      fontWeight: 900,
    },
    h6: {
      fontWeight: 900,
    },
    subtitle1: {
      fontWeight: 500,
    },
    button: {
        fontWeight: 600,
    },
    body1: {
        fontWeight: 500,
    }
  },
});

export default theme;

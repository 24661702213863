import * as React from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { personList, timeSlot } from "../../utils/constants/temp";
export default function MakeReservation() {
  return (
    <>
      <Container maxWidth="lg">
        <Divider sx={{ my: 1 }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ my: 1 }}>
            Party Size
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            fullWidth
            options={personList}
            getOptionLabel={(Person) => Person.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="2 people"
                variant="standard"
                size="small"
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box>
            <Typography variant="body1" sx={{ my: 1 }}>
              Date
            </Typography>
            <TextField
              id="date"
              type="date"
              size="small"
              variant="standard"
              defaultValue="2017-05-24"
              sx={{
                width: 150,
                bgcolor: "white",
                "& .MuiInputBase-root": {
                  border: "none",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mx: 2 }}>
            <Typography variant="body1" sx={{ my: 1, mx: 2 }}>
              Time
            </Typography>
            <Autocomplete
              id="combo-box-demo"
              options={timeSlot}
              getOptionLabel={(Time) => Time.label}
              sx={{
                width: 150,
                mx: 2,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="8:00 PM"
                  variant="standard"
                  sx={{
                    bgcolor: "white",
                    "& .MuiInputBase-root": {
                      border: "none",
                    },
                  }}
                  size="small"
                />
              )}
            />
          </Box>
        </Box>
        <Button variant="contained" size="large" sx={{ my: 2, width: "100%" }}>
          Find a time
        </Button>
        <Button
          variant="outlined"
          size="large"
          sx={{ marginBottom: 2, width: "100%" }}
        >
          Show next available
        </Button>
      </Container>
    </>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ReservationListItem from "./ReservationListItem";
import ChairAltRoundedIcon from "@mui/icons-material/ChairAltRounded";
import UpcomingRoundedIcon from "@mui/icons-material/UpcomingRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";

const buttons = [
  <Button key="one" fullWidth>
    Seated
  </Button>,
  <Button key="two" fullWidth>
    Upcoming
  </Button>,
  <Button key="three" fullWidth>
    Waiting
  </Button>,
];

const ReservationsList: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        "& > *": {
          m: 1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonGroup size="large" aria-label="reservation types button group">
          {buttons}
        </ButtonGroup>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          color="primary"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            m: 1,
          }}
          component="div"
        >
          <ChairAltRoundedIcon fontSize="small" />
          Seated
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            m: 1,
          }}
          component="div"
        >
          <PersonOutlineRoundedIcon fontSize="small" />
          10
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "70vh",
        }}
      >
        <List dense={true} sx={{ width: "100%", bgcolor: "background.paper" }}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
            <React.Fragment key={value}>
              <ReservationListItem />
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ReservationsList;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import MuiDrawer from "@mui/material/Drawer";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCard from "./TableCard";
import InputFieldWithIncDec from "../common/InputFields/InputFieldWithIncDec";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import { AppDispatch, RootState } from "../../store";
import { getTablesBySection } from "../../store/reducers/tablesSlice";
import { ITable } from "../../utils/interfaces/entities/table.interface";

export const drawerWidth = 50;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth + "%",
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth + "%",
  zIndex: theme.zIndex.appBar + 1,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const TABLE_ITEM: ITable = {
  _id: "1",
  table_number: 1,
  table_description: "",
  min_capacity: 2,
  table_capacity: 4,
  preview_images: [],
  table_status: "available",
  table_section: "6353ce6c92cee94ca51e4b23",
  table_restaurant: "6353ce6c92cee94ca51e4b23"
};

interface INewTableFormSideBarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const NewTableForm: React.FC<INewTableFormSideBarProps> = (props) => {
  const { open, handleDrawerClose } = props;

  const [images, setImages] = useState<File[]>([]);
  const [inputKey, setInputKey] = useState(0); // this is used to reset the file input
  const [imageUploadError, setImageUploadError] = useState<null | string>();
  const [loadSections, setLoadSections] = React.useState(false);

  const sectionsList = useSelector(
    (state: RootState) => state.tables.tablesBySection
  );
  const isLoading = useSelector((state: RootState) => state.tables.loading);
  const dispatch = useDispatch<AppDispatch>();

  const getAllTables = () => {
    dispatch(getTablesBySection("6353ce6c92cee94ca51e4b23"));
  };

  useEffect(() => {
    if (loadSections) {
      getAllTables();
    }
  }, [loadSections]);

  const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    if (images.length + files.length > 3) {
      setImageUploadError("You can only upload up to 3 images");
      return;
    }

    const newImages = [...images, ...files];
    setImages(newImages);
    setInputKey(inputKey + 1); // reset the file input
  };

  const handleDeleteImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  return (
    <Drawer variant="permanent" open={open} anchor="right">
      <Box
        sx={{
          m: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              mr: 1,
            }}
          >
            <CloseRoundedIcon fontSize="large" />
          </IconButton>
          New Table
        </Typography>

        <Box
          sx={{
            m: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack justifyContent="flex-end">
                <Autocomplete
                  size="small"
                  id="menu-section-list"
                  open={loadSections}
                  onOpen={() => {
                    setLoadSections(true);
                  }}
                  onClose={() => {
                    setLoadSections(false);
                  }}
                  fullWidth
                  options={sectionsList}
                  getOptionLabel={(section) => section.section_name}
                  loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Menu Section"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField size="small" name="name" label="Name" />
                <InputFieldWithIncDec
                  title="Min Guests"
                  handleDecrement={() => {}}
                  handleIncrement={() => {}}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                  />
                </InputFieldWithIncDec>

                <InputFieldWithIncDec
                  title="Max Guests"
                  handleDecrement={() => {}}
                  handleIncrement={() => {}}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                  />
                </InputFieldWithIncDec>

                <TextField
                  size="small"
                  multiline
                  rows={3}
                  maxRows={3}
                  name="description"
                  label="Description"
                />

                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Available for online reservations?"
                  labelPlacement="start"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TableCard {...TABLE_ITEM} mode="view" />
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <Stack direction="row" spacing={1}>
                  {images.map((image, index) => (
                    <Box
                      key={image.name}
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: 150,
                          height: 125,
                          overflow: "hidden",
                          borderRadius: 1,
                          "& img": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          },
                        }}
                      >
                        <img
                          // className={classes.image}
                          src={URL.createObjectURL(image)}
                        />
                      </Box>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          m: 1,
                          color: "white",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                          },
                        }}
                        size="small"
                        onClick={() => handleDeleteImage(index)}
                      >
                        <CloseRoundedIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Stack>

                <input
                  type="file"
                  id="fileUploadButton"
                  accept=".jpg, .jpeg, .png"
                  style={{ display: "none" }}
                  onChange={handleAddImage}
                />
                <label htmlFor={"fileUploadButton"}>
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <AddPhotoAlternateRoundedIcon />
                      </SvgIcon>
                    }
                  >
                    Upload
                  </Button>
                </label>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            m: 2,
          }}
        >
          <Button variant="text">Close</Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              mx: 1,
            }}
          >
            Save
          </Button>
          <Button variant="contained" color="primary">
            Save and Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NewTableForm;

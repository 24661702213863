import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addData } from "./../../utils/api/api";
import { API_ENDPOINTS } from "../../utils/api/endpoints";
import {
  IUSER_LOGIN,
  IUSER_SIGNUP,
  IBUSINESS_USER_LOGIN,
  IBUSINESS_USER_SIGNUP,
} from "../../utils/interfaces/common.interface";

const {
  USER_LOGIN,
  USER_SIGNUP,
  BUSINESS_USER_LOGIN,
  BUSINESS_USER_SIGNUP,
} = API_ENDPOINTS;

export const userLogin = createAsyncThunk(
  USER_LOGIN,
  async (user: IUSER_LOGIN, thunkAPI) => {
    try {
      const result = await addData(USER_LOGIN, user);
      if (result.status === 200) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue({
          message: "Unable to login",
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: "Unable to login",
      });
    }
  }
);

export const userSignup = createAsyncThunk(
  USER_SIGNUP,
  async (user: IUSER_SIGNUP, thunkAPI) => {
    try {
      const result = await addData(USER_SIGNUP, user);
      console.log("result", result);
      if (result.status === 200) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue({
          message: "Unable to signup",
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: "Unable to signup",
      });
    }
  }
);

export const businessUserLogin = createAsyncThunk(
  BUSINESS_USER_LOGIN,
  async (businessUser: IBUSINESS_USER_LOGIN, thunkAPI) => {
    try {
      const result = await addData(BUSINESS_USER_LOGIN, businessUser);
      console.log(result, "result");
      if (result.status === 200) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue({
          message: "Unable to login",
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: "Unable to login",
      });
    }
  }
);

export const businessUserSignup = createAsyncThunk(
  BUSINESS_USER_SIGNUP,
  async (businessUser: IBUSINESS_USER_SIGNUP, thunkAPI) => {
    try {
      const result = await addData(BUSINESS_USER_SIGNUP, businessUser);
      console.log(result, "result");
      if (result.status === 200) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue({
          message: "Unable to signup",
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: "Unable to signup",
      });
    }
  }
);

export interface IInitialState {
  user?: object | any;
  userType: "user" | "business";
  isLoggedIn: boolean;
  token?: string;
  error: {
    message: string;
    status: "error" | "success" | "info" | "warning" | "idle";
  };
  userRegistered: boolean;
}

const initialState: IInitialState = {
  user: {},
  userType: "user",
  isLoggedIn: localStorage.getItem("jwt") ? true : false,
  token: "",
  error: {
    message: "",
    status: "idle",
  },
  userRegistered: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialize: (state) => {
      state.userRegistered = false;
    },
    logout: (state) => {
      state.user = {};
      state.userType = "user";
      state.token = "";
      state.isLoggedIn = false;
      localStorage.removeItem("jwt");
      state.error = {
        message: "",
        status: "idle",
      };
    },
    updateUserData: (state, action) => {
      state.user = action.payload.data.user;
    },
    updateToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("jwt", action.payload);
    },
    clearError: (state) => {
      state.error = {
        message: "",
        status: "idle",
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.userType = "user";
      state.isLoggedIn = true;
      localStorage.setItem("jwt", action.payload.token);
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      const { message } = (action.payload as unknown) as {
        message: string;
      };

      state.error = {
        message: message && message.length > 0 ? message : "Unable to login",
        status: "error",
      };
    });
    builder.addCase(userSignup.fulfilled, (state, action) => {
      state.userRegistered = true;
    });
    builder.addCase(userSignup.rejected, (state, action) => {
      const { message } = (action.payload as unknown) as {
        message: string;
      };

      state.error = {
        message: message && message.length > 0 ? message : "Unable to login",
        status: "error",
      };
    });
    builder.addCase(businessUserLogin.fulfilled, (state, action) => {
      state.user = action.payload.business_user;
      state.userType = "business";
      state.isLoggedIn = true;
      localStorage.setItem("jwt", action.payload.token);
    });
    builder.addCase(businessUserLogin.rejected, (state, action) => {
      const { message } = (action.payload as unknown) as {
        message: string;
      };

      state.error = {
        message: message.length > 0 ? message : "Unable to login",
        status: "error",
      };
    });
    builder.addCase(businessUserSignup.fulfilled, (state, action) => {
      state.userRegistered = true;
    });
    builder.addCase(businessUserSignup.rejected, (state, action) => {
      const { message } = (action.payload as unknown) as {
        message: string;
      };

      state.error = {
        message: message.length > 0 ? message : "Unable to login",
        status: "error",
      };
    });
  },
});

export const {
  logout,
  updateUserData,
  updateToken,
  clearError,
  initialize,
} = authSlice.actions;

export default authSlice.reducer;

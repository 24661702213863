import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FindRestaurantAppBar from "../../components/find-restaurant/FindRestaurantAppBar";

const findRestaurant: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ flexGrow: 1 }}>
          <FindRestaurantAppBar />
        </Box>
      </Grid>
    </Grid>
  );
};

export default findRestaurant;

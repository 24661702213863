import { createSlice } from "@reduxjs/toolkit";
import { IItem } from "../../utils/constants/temp";

export interface IInitialState {
  selectedItem: IItem | null;
}

const initialState: IInitialState = {
  selectedItem: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
});

export const { addItemToCart } = cartSlice.actions;

export default cartSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export interface IInitialState {
  filters: { [key: string]: string };
  query: string;
}

const initialState: IInitialState = {
  filters: {},
  query: "",
};

const generateQuery = (filters: { [key: string]: string }) => {
  let query = "";
  for (let key in filters) {
    query += `${key}=${filters[key]}&`;
  }
  return query;
};

const remove = (obj: { [key: string]: string }, key: string) => {
  let newObj = { ...obj };
  delete newObj[key];
  return newObj;
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    addFilter: (state, action) => {
      state.filters[action.payload.key] = action.payload.value;
      state.query = generateQuery(state.filters);
    },
    removeFilter: (state, action) => {
      state.filters = remove(state.filters, action.payload);
      state.query = generateQuery(state.filters);
    },
  },
});

export const { addFilter, removeFilter } = filtersSlice.actions;

export default filtersSlice.reducer;

import { getAllData } from "../utils/api/api";
import { API_ENDPOINTS } from "../utils/api/endpoints";
import {
  ISECTION_WITH_TABLES,
  ITable,
} from "../utils/interfaces/entities/table.interface";

const {
  GET_SECTIONS_BY_RESTAURANT,
  GET_TABLES_BY_RESTAURANT,
  GET_AVAILABLE_TABLES_BY_RESTAURANT,
} = API_ENDPOINTS;

interface ISectionResponse {
  status: string;
  data: ISECTION_WITH_TABLES[] | [];
  message: string;
}

interface ITableResponse {
  status: string;
  data: ITable[] | [];
  message: string;
}

export const getSectionsByRestaurant = async (
  id: string
): Promise<ISectionResponse> => {
  try {
    const result = await getAllData(`${GET_SECTIONS_BY_RESTAURANT}/${id}`);
    if (result.status === 200) {
      return {
        status: "success",
        data: result.data.sections,
        message: "Tables fetched successfully",
      };
    } else {
      return {
        status: "error",
        message: "Unable to fetch tables",
        data: [],
      };
    }
  } catch (error) {
    return {
      status: "error",
      message: "Unable to fetch tables",
      data: [],
    };
  }
};

export const getAllAvailabeTablesByRestaurant = async (
  id: string
): Promise<ITableResponse> => {
  try {
    const result = await getAllData(
      `${GET_TABLES_BY_RESTAURANT}/${id}${GET_AVAILABLE_TABLES_BY_RESTAURANT}`
    );
    if (result.status === 200) {
      return {
        status: "success",
        data: result.data.tables,
        message: "Tables fetched successfully",
      };
    } else {
      return {
        status: "error",
        message: "Unable to fetch tables",
        data: [],
      };
    }
  } catch (error) {
    return {
      status: "error",
      message: "Unable to fetch tables",
      data: [],
    };
  }
};

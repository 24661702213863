import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { ITable } from "../../utils/interfaces/entities/table.interface";
import NoImage from "../common/NoImage";

interface ITableCardProps extends ITable {
  mode: "edit" | "view" | "create";
}

const TableCard: React.FC<ITableCardProps> = (props) => {
  const {
    table_number,
    table_capacity,
    table_description,
    min_capacity,
    preview_images,
    mode,
  } = props;
  return (
    <Card
      variant="outlined"
      sx={{
        position: "relative",
      }}
    >
      {mode !== "view" && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            m: 1,
          }}
        >
          {mode === "edit" && (
            <IconButton>
              <EditRoundedIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Box
        sx={{
          background: "white",
          py: 2,
          width: "100%",
          height: "140px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {preview_images.length === 0 && <NoImage />}
        {preview_images.length > 0 && (
          <img src={preview_images[0]} alt={table_number.toString()} height="140px" />
        )}
      </Box>
      <CardContent
        sx={{
          borderTop: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{table_number}</Typography>

          <Typography
            variant="body1"
            color="primary"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <PersonOutlineRoundedIcon fontSize="small" />
            {min_capacity} - {table_capacity}
          </Typography>
        </Box>

        {mode === "create" && (
          <Typography variant="body2" color="text.secondary">
            {table_description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

TableCard.defaultProps = {
  preview_images: [],
};

export default TableCard;

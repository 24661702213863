import * as React from "react";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import ProfileMenu from "../../../components/layout/ProfileMenu";
import { paths } from "../../../routes/paths";

const Header: React.FC = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "common.white",
        boxShadow: "none",
        color: "common.black",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link
            variant="h4"
            color="primary"
            sx={{ flexGrow: 1 }}
            underline="none"
            href={paths.find_rastaurant}
          >
            {/* // TODO: Add LOGO HERE  */}
            reserve
          </Link>

          <ProfileMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;

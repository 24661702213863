import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import InputFieldWithIncDec from "../common/InputFields/InputFieldWithIncDec";
import DeleteIcon from "@mui/icons-material/Delete";
import PIZZA_ICON from "../../static/img/temp/pizza.png";
import { useNavigateParams } from "../../utils/hooks/useNavigateParams";

const OrderCartItem: React.FC = () => {
  const navigate = useNavigateParams();

  const handleOrderSelect = (sectionID: string) => {
    navigate("/tables", {
      selectedOrder: sectionID,
    });
  };

  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      component="a"
      sx={{
        width: "100%",
      }}
      secondaryAction={
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              "& > *": {
                m: 1,
              },
            }}
          >
            <InputFieldWithIncDec
              handleDecrement={() => {}}
              handleIncrement={() => {}}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                sx={{
                  width: "50px",
                }}
              />
            </InputFieldWithIncDec>
            <IconButton edge="end" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Box>
        </React.Fragment>
      }
    >
      <ListItemButton
        onClick={() =>
          handleOrderSelect(Math.floor(Math.random() * 1000).toString())
        }
      >
        <ListItemAvatar>
          <Avatar src={PIZZA_ICON} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            variant: "h6",
            color: "textSecondary",
          }}
          primary="Pizza"
          secondary={"Rs. 250"}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default OrderCartItem;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TableCard from "./TableCard";
import NewTableForm from "./NewTableForm";
import LinearProgress from "@mui/material/LinearProgress";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { RootState } from "../../store";
import { ITable } from "../../utils/interfaces/entities/table.interface";

const TablesList: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isSectionSelected, setIsSectionSelected] = React.useState<boolean>(
    false
  );

  const [searchParams] = useSearchParams();

  const tablesBySection = useSelector(
    (state: RootState) => state.tables.tablesList
  );
  const tablesByRestaurant = useSelector(
    (state: RootState) => state.tables.tablesBySection
  );
  const isLoading = useSelector((state: RootState) => state.tables.loading);

  const handleNewTableDrawerClickOpen = () => {
    setOpen(true);
  };

  const handleNewTableDrawerClose = () => {
    setOpen(false);
  };

  const tablesList = () => {
    if (isSectionSelected) {
      return tablesBySection;
    } else {
      let tables = [] as ITable[];
      tablesByRestaurant.forEach((section) => {
        section.tables.forEach((table) => {
          tables.push(table);
        });
      });
      return tables || [];
    }
  };

  const getPageTitle = (): string => {
    let pageTitle = "";
    if (isSectionSelected) {
      tablesByRestaurant.forEach((section) => {
        if (section._id === searchParams.get("selectedSection")) {
          pageTitle = section.section_name + " Tables";
        }
      });
    } else {
      pageTitle = "All Tables";
    }

    return pageTitle;
  };

  useEffect(() => {
    const sectionID = searchParams.get("selectedSection");
    if (sectionID && sectionID.length > 0) {
      setIsSectionSelected(true);
    } else {
      setIsSectionSelected(false);
    }
  }, [searchParams]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <AppBar
          position="static"
          sx={{
            bgcolor: "background.paper",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h5" color="textSecondary">
                {getPageTitle()}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                endIcon={<AddRoundedIcon />}
                onClick={handleNewTableDrawerClickOpen}
              >
                New Table
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        {isLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}

        <Box
          sx={{
            overflowY: "auto",
            height: "79vh",
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
            }}
          >
            {tablesList().length > 0 &&
              tablesList().map((table) => (
                <Grid key={uuidv4()} item xs={6} md={4} lg={3}>
                  <TableCard {...table} mode="edit" />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
      <NewTableForm
        open={open}
        handleDrawerClose={() => handleNewTableDrawerClose()}
      />
    </>
  );
};

export default TablesList;

import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import ChairAltRoundedIcon from "@mui/icons-material/ChairAltRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";

const TableListItem: React.FC = () => {
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        position: "relative",
        m: theme.spacing(0),
        p: theme.spacing(2, 0, 2, 2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          T1
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          sx={{
            fontWeight: "bold",
          }}
        >
          Occupied
        </Typography>
      </Box>
      <Typography>
        <ChairAltRoundedIcon />
        Seated
      </Typography>
      <Typography>
        <PersonOutlineRoundedIcon />3 Guests
      </Typography>
      <Box
        sx={{
          position: "absolute",
          top: -1,
          right: -1,
          height: "100%",
          width: "6%",
          m: theme.spacing(0),
          paddingBottom: "2px",
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
          backgroundColor: "primary.main",
        }}
      />
    </Paper>
  );
};

export default TableListItem;

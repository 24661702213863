import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { SvgIconComponent } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

interface IOptionSelectionCardProps {
  selected: boolean;
  Icon: SvgIconComponent;
  title: string;
  handleClick: () => void;
}

const OptionSelectionCard: React.FC<IOptionSelectionCardProps> = ({
  selected,
  Icon,
  title,
  handleClick,
}) => {
  const theme = useTheme();

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: theme.spacing(3),
        margin: theme.spacing(2, 0),
        cursor: "pointer",
        borderWidth: "4px",
        ...(selected && {
          border: `4px solid ${theme.palette.primary.main}`,
          bgcolor: theme.palette.primary.light,
        }),
        ...(!selected && {
          "&:hover": {
            border: `4px solid ${theme.palette.primary.main}`,
          },
        }),
      }}
      onClick={() => handleClick()}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: theme.spacing(2),
            ...(!selected && {
              bgcolor: theme.palette.common.white,
              border: `3px solid ${theme.palette.primary.main}`,
            }),
            ...(selected && {
              bgcolor: theme.palette.primary.main,
              border: `3px solid ${theme.palette.primary.main}`,
            }),
          }}
          variant="rounded"
        >
          <Icon
            fontSize="large"
            {...(selected === false && { color: "primary" })}
          />
        </Avatar>

        <Typography variant="h6">{title}</Typography>
      </Box>
    </Paper>
  );
};

export default OptionSelectionCard;

import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Header from "./Header";
import RestaurantSideBar, {
  DrawerHeader,
} from "./SideBar/RestaurantSideBar";

interface IRestuarantLayoutProps {}

const RestuarantLayout: React.FC<IRestuarantLayoutProps> = () => {
  const [isLoggedIn] = React.useState(true);
  const [restaurantDrawerOpen, setRestaurantDrawerOpen] = React.useState(false);

  const handleRestaurantDrawerOpen = () => {
    setRestaurantDrawerOpen(true);
  };

  const handleRestaurantDrawerClose = () => {
    setRestaurantDrawerOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Header
        isLoggedIn={isLoggedIn}
        restaurantDrawerOpen={restaurantDrawerOpen}
        handleRestaurantDrawerOpen={handleRestaurantDrawerOpen}
        handleRestaurantDrawerClose={handleRestaurantDrawerClose}
      />
      {isLoggedIn && (
        <RestaurantSideBar
          open={restaurantDrawerOpen}
          handleDrawerClose={() => handleRestaurantDrawerClose()}
        />
      )}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default RestuarantLayout;
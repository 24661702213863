import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// * COMPONENTS IMPORTS
import RestuarantLayout from "../layout/Restaurant";
import CustomerLayout from "../layout/Customer";
// * UTILS
import {
  onlyPublicRoutes,
  restaurantPrivateRoutes,
  customerPrivateRoutes,
  publicRoutes,
  paths,
} from "./paths";
import PrivateRoutes from "./privateRoutes";
import PublicRoutes from "./publicRoutes";
import OnlyPublicRoutes from "./onlyPublicRoutes";
import { RootState } from "../store";

const RouteProvider: React.FC = () => {
  const _publicRoutes = Object.values(publicRoutes);
  const _onlyPublicRoutes = Object.values(onlyPublicRoutes);
  const _restaurantPrivateRoutes = Object.values(restaurantPrivateRoutes);
  const _customerPrivateRoutes = Object.values(customerPrivateRoutes);

  const navigate = useNavigate();

  const userType = useSelector((state: RootState) => state.auth.userType);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const isUserTypeCustomer = () => {
    return userType === "user";
  };

  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate(paths.login);
    }
  }, [isUserLoggedIn]);

  return (
    <Routes>
      <Route element={<OnlyPublicRoutes />}>
        {_onlyPublicRoutes.map((route: any) => (
          <Route path={route.path} element={route.component} key={uuidv4()} />
        ))}
      </Route>
      <Route
        element={
          isUserTypeCustomer() ? <CustomerLayout /> : <RestuarantLayout />
        }
      >
        {_publicRoutes.map((route: any) => (
          <Route path={route.path} element={route.component} key={uuidv4()} />
        ))}
      </Route>
      <Route element={<RestuarantLayout />}>
        <Route element={<PrivateRoutes />}>
          {_restaurantPrivateRoutes.map((route: any) => (
            <Route path={route.path} element={route.component} key={uuidv4()} />
          ))}
        </Route>
      </Route>
      <Route element={<CustomerLayout />}>
        <Route element={<PrivateRoutes />}>
          {_customerPrivateRoutes.map((route: any) => (
            <Route path={route.path} element={route.component} key={uuidv4()} />
          ))}
        </Route>
      </Route>
      {/* //* ADD NOT FOUND PAGE */}
      <Route path="*" element={<h1>NOT FOUND</h1>} />
    </Routes>
  );
};

export default RouteProvider;

import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import GuestsList from "../../components/guests/GuestsList";
import AddGuestDialog from "../../components/manage-reservations/AddGuestModal/AddGuestDialog";

const Guests: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 2,
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Guests
            </Typography>

            <AddGuestDialog />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <GuestsList />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Guests;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import RouteProvider from "./routes/routes";
import { AppDispatch } from "./store";
import { initialize } from "./store/reducers/authSlice";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(initialize());
  }, []);

  return <RouteProvider />;
}

export default App;

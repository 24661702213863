import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuTabs from "../../components/menu/MenuTabs";

const Menu: React.FC = () => {
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "90vh",
          }}
        >
          <MenuTabs mode="edit" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Menu;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Image from "../../common/Image";
import ResponsiveDialog from "../../common/Dialog/ResponsiveDialog";
import IconButtonWithToolTip from "../../common/Button/IconButtonWithTooltip";
import InputFieldWithIncDec from "../../common/InputFields/InputFieldWithIncDec";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import { addItemToCart } from "../../../store/reducers/cartSlice";
import { IItem } from "../../../utils/constants/temp";
import { fieldNames } from "../../../utils/constants/formsConstants";

interface IAddItemToCartDialogProps {
  item: IItem;
}

const AddItemToCartDialog: React.FC<IAddItemToCartDialogProps> = (props) => {
  const { item } = props;

  const [openGuestModal, setOpenGuestModal] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const handleNewGuestClickOpen = () => {
    setOpenGuestModal(true);
  };

  const handleNewGuestClose = () => {
    setOpenGuestModal(false);
  };

  useEffect(() => {
    dispatch(addItemToCart(item));
  }, [open, item]);

  return (
    <>
      <IconButtonWithToolTip
        tooltipText="Add Item to Cart"
        IconButtonProps={{ sx: { mx: 1 } }}
        onClick={handleNewGuestClickOpen}
      >
        <AddShoppingCartRoundedIcon />
      </IconButtonWithToolTip>

      <ResponsiveDialog
        open={openGuestModal}
        title={item.name}
        handleClose={handleNewGuestClose}
        actions={
          <>
            <Button onClick={handleNewGuestClose}>Cancel</Button>
            <Button onClick={handleNewGuestClose}>Add to cart</Button>
          </>
        }
      >
        <Box
          sx={{
            minWidth: "100%",
            mb: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image height={160} maxWidth={250} src={item.image} />
          </Box>
          <Divider />
          <Stack spacing={1}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography variant="h6">{item.name}</Typography>
                <Typography variant="body2">{item.description}</Typography>
              </Box>

              <Box>
                <Typography variant="h6">Rs. {item.price}</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography variant="body1">Quantity</Typography>
              </Box>

              <Box>
                <InputFieldWithIncDec
                  handleDecrement={() => {}}
                  handleIncrement={() => {}}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: 100 }}
                  />
                </InputFieldWithIncDec>
              </Box>
            </Box>
            {item.options && item.options.length > 0 && (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography variant="body1">Variations</Typography>

                <Box
                  sx={{
                    bgcolor: "primary.light",
                    borderRadius: 1,
                    border: 1,
                    borderColor: "primary.main",
                    py: 1,
                    px: 2,
                    mt: 1,
                  }}
                >
                  <RadioGroup
                    aria-labelledby="variation-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    {item.options.map((variation, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          color="primary"
                          label={variation.name}
                        />

                        <Typography variant="body2">
                          Rs. {variation.price}
                        </Typography>
                      </Box>
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
            )}

            <Box>
              <Typography variant="body1">Special Instructions</Typography>
              <Typography variant="body2" gutterBottom>
                Any specific preferences? Let the restaurant know.
              </Typography>

              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                placeholder="e.g. No onions, extra chilli, etc."
              />
            </Box>
          </Stack>
        </Box>
      </ResponsiveDialog>
    </>
  );
};

export default AddItemToCartDialog;

import React from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ChairAltRoundedIcon from "@mui/icons-material/ChairAltRounded";
import TableBarRoundedIcon from "@mui/icons-material/TableBarRounded";
import { AppDispatch } from "../../store";
import { setSelectedSection } from "../../store/reducers/tablesSlice";
import { useNavigateParams } from "../../utils/hooks/useNavigateParams";
import { ISection } from "../../utils/interfaces/entities/table.interface";

export interface ISectionListItemProps extends ISection {}

const SectionListItem: React.FC<ISectionListItemProps> = (props) => {
  const { section_name, total_capacity, total_tables } = props;

  const navigate = useNavigateParams();
  const dispatch = useDispatch<AppDispatch>();

  const handleSectionSelect = () => {
    dispatch(setSelectedSection(props._id));
    navigate("/tables", {
      selectedSection: props._id,
    });
  };

  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      component="a"
      sx={{
        width: "100%",
      }}
    >
      <ListItemButton onClick={() => handleSectionSelect()}>
        <ListItemText
          primaryTypographyProps={{
            variant: "h6",
            color: "textSecondary",
          }}
          primary={section_name}
          secondary={
            <React.Fragment>
              <Box
                sx={{
                  "& > *": {
                    m: 0.5,
                  },
                }}
              >
                <Chip
                  icon={<TableBarRoundedIcon />}
                  label={`${total_tables} Tables`}
                  variant="outlined"
                />
                <Chip
                  icon={<ChairAltRoundedIcon />}
                  label={`${total_capacity} Seats`}
                  variant="outlined"
                />
              </Box>
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SectionListItem;

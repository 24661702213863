import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Container, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import RestaurantCard from "./RestaurantCard";
import VerticalFilters from "./VerticalFilters";
import theme from "../../theme";
import Button from "@mui/material/Button";
import { personList, timeSlot, Filter } from "../../utils/constants/temp";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: theme.palette.primary.main,
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    color: theme.palette.primary.main,
    transition: theme.transitions.create("width"),

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                id="date"
                type="date"
                size="small"
                defaultValue="2017-05-24"
                sx={{
                  width: 220,
                  bgcolor: "white",
                  "& .MuiInputBase-root": {
                    borderRight: "none",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Autocomplete
                id="combo-box-demo"
                options={timeSlot}
                getOptionLabel={(Time) => Time.label}
                sx={{
                  width: 200,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="8:00 PM"
                    variant="outlined"
                    sx={{
                      bgcolor: "white",
                      "& .MuiInputBase-root": {
                        borderRadius: 0,
                        borderRight: "none",
                        borderLeft: "none",
                      },
                    }}
                    size="small"
                  />
                )}
              />
              <Autocomplete
                id="combo-box-demo"
                options={personList}
                getOptionLabel={(Person) => Person.label}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="2 people"
                    variant="outlined"
                    sx={{
                      bgcolor: "white",
                      "& .MuiInputBase-root": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderLeft: "none",
                      },
                    }}
                    size="small"
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
              <Button variant="contained" size="large">
                Find Table
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent="center" spacing={2}>
          <Grid item container xs={12} md={4} lg={3}>
            <VerticalFilters />
          </Grid>
          <Grid item container xs={12} md={8} justifyContent="flex-start" alignContent="flex-start">
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderColor: "divider",
                  "& > *": {
                    m: 1,
                  },
                }}
              >
                <Typography variant="body1" fontWeight={"bold"}>
                  50 restaurants available
                </Typography>
                <Autocomplete
                  id="combo-box-demo"
                  options={Filter}
                  getOptionLabel={(Filter) => Filter.label}
                  sx={{ width: 200, borderRadius: 1 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Featured"
                      variant="outlined"
                      sx={{ bgcolor: "white", borderRadius: 1 }}
                      size="small"
                    />
                  )}
                />
              </Box>
            </Grid>
            {[1, 2, 3, 4, 5].map((item) => (
              <Grid key={item} item xs={12}>
                <RestaurantCard />
                <Divider
                  sx={{
                    mt: 2,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

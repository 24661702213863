import React from "react";
import { useTheme } from "@mui/system";
import Paper from "@mui/material/Paper";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import AddRounded from "@mui/icons-material/AddRounded";
import NewMenuItemForm from "./NewMenuItemForm";

export interface INewMenuItemCardProps {
  title: string;
}

const NewMenuItemCard: React.FC<INewMenuItemCardProps> = (props) => {
  const { title } = props;

  const theme = useTheme();

  const [
    newMenuItemFormDrawerOpen,
    setNewMenuItemFormDrawerOpen,
  ] = React.useState(false);

  const handleNewMenuItemFormDrawerOpen = () => {
    setNewMenuItemFormDrawerOpen(true);
  };

  const handleNewMenuItemFormDrawerClose = () => {
    setNewMenuItemFormDrawerOpen(false);
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          minHeight: "250px",
          height: "100%",
        }}
      >
        <ButtonBase
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "250px",
            height: "100%",
            width: "100%",
            borderRadius: "16px",
          }}
          onClick={handleNewMenuItemFormDrawerOpen}
        >
          <AddRounded
            fontSize="large"
            sx={{
              color: theme.palette.primary.main,
              p: 2,
              backgroundColor: theme.palette.primary.light,
              borderRadius: "50%",
              border: "1px dashed",
              borderColor: theme.palette.primary.main,
            }}
          />
          <Typography variant="h6" color="primary" component="div">
            New {title}
          </Typography>
        </ButtonBase>
      </Paper>
      <NewMenuItemForm
        open={newMenuItemFormDrawerOpen}
        handleDrawerClose={handleNewMenuItemFormDrawerClose}
      />
    </>
  );
};

export default NewMenuItemCard;

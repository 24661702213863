import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuTabs from "../../components/menu/MenuTabs";
import OrderCart from "../../components/new-order/OrderCart";

const NewOrder: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "90vh",
          }}
        >
          <MenuTabs mode="order" />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderLeft: 1,
            borderColor: "divider",
          }}
        >
          <OrderCart />
        </Box>
      </Grid>
    </Grid>
  );
};

export default NewOrder;

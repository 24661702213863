import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  clearError,
  userSignup,
  businessUserSignup,
} from "../../store/reducers/authSlice";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Step from "@mui/material/Step";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import OptionSelectionCard from "../../components/common/Card/OptionSelectionCard";
import { useTheme } from "@mui/material/styles";
import SignupImage from "../../static/img/user_login.svg";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import PermIdentityRoundedIcon from "@mui/icons-material/PermIdentityRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import { AppDispatch, RootState } from "../../store";
import { paths } from "../../routes/paths";
import { fieldNames, messages } from "../../utils/constants/formsConstants";
import { setAccountType } from "../../store/reducers/accountTypeSlice";
import { IAccountType } from "../../utils/interfaces/common.interface";

const stepStyles = {
  stepper: {
    "& .MuiStepConnector-line": {
      borderColor: "common.white",
    },
  },
  label: {
    "& .MuiStepLabel-root": {
      color: "common.white", // circle color (COMPLETED)
      fontWeight: 700,
    },
    "& .MuiStepLabel-label.MuiStepLabel-label": {
      color: "common.white", // Just text label (COMPLETED)
      fontWeight: 700,
    },
    "& .MuiStepLabel-root .Mui-active": {
      color: "common.white", // circle color (ACTIVE)
      fontSize: "1.6rem",
      fontWeight: 700,
    },
    "& .MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
      color: "common.white", // Just text label (ACTIVE)
      fontSize: "1.6rem",
      fontWeight: 700,
    },
  },
};

interface IInitialState {
  first_name: string;
  last_name: string;
  business_name: string;
  email: string;
  password: string;
  confirm_password: string;
}

const initialState: IInitialState = {
  first_name: "",
  last_name: "",
  business_name: "",
  email: "",
  password: "",
  confirm_password: "",
};

const businessAccountInformationSchema = Yup.object().shape({
  business_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, messages.notValid)
    .max(40)
    .required(messages.isRequired),
  email: Yup.string()
    .email(messages.notValid)
    .required(messages.isRequired),
  password: Yup.string()
    .min(8, messages.password)
    .required(messages.isRequired),
  confirm_password: Yup.string()
    .required(messages.isRequired)
    .oneOf([Yup.ref("password")], messages.notMatch),
});

const personalAccountInformationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, messages.notValid)
    .max(40)
    .required(messages.isRequired),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, messages.notValid)
    .max(40)
    .required(messages.isRequired),
  email: Yup.string()
    .email(messages.notValid)
    .required(messages.isRequired),
  password: Yup.string()
    .min(8, messages.password)
    .required(messages.isRequired),
  confirm_password: Yup.string()
    .required(messages.isRequired)
    .oneOf([Yup.ref("password")], messages.notMatch),
});

const Signup: React.FC = () => {
  const theme = useTheme();
  const accountType = useSelector(
    (state: RootState) => state.accountType.accountType
  );
  const userRegistered = useSelector(
    (state: RootState) => state.auth.userRegistered
  );
  const error = useSelector((state: RootState) => state.auth.error);

  const [userSelectedAccountType, setUserSelectedAccountType] = useState<
    Boolean
  >(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [stepLabels, setStepLabels] = useState<string[]>([
    "Select Account Type",
    "Personal Information",
  ]);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const chooseAccountType = (accountType: string) => {
    dispatch(setAccountType(accountType));
    setUserSelectedAccountType(true);
  };

  const formikUserSignup = useFormik({
    initialValues: initialState,
    validationSchema: personalAccountInformationSchema,
    onSubmit: (values) => {
      dispatch(userSignup(values));
    },
  });

  const formikBusinessUserSignup = useFormik({
    initialValues: initialState,
    validationSchema: businessAccountInformationSchema,
    onSubmit: (values) => {
      dispatch(businessUserSignup(values));
    },
  });

  useEffect(() => {
    if (accountType === "user") {
      setStepLabels(["Select Account Type", "Personal Information"]);
    } else {
      setStepLabels(["Select Account Type", "Business Information"]);
    }
  }, [accountType]);

  useEffect(() => {
    if (userRegistered) {
      navigate(paths.login);
    }
  }, [userRegistered]);

  useEffect(() => {
    dispatch(clearError());
  }, []);

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={0} md={4}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Stepper
            orientation="vertical"
            activeStep={currentStep - 1}
            sx={stepStyles.stepper}
          >
            {stepLabels.map((label: string, index: number) => (
              <Step sx={stepStyles.label} key={uuidv4()}>
                <StepLabel
                  icon={
                    currentStep >= index + 1 ? (
                      <CheckCircleOutlineRoundedIcon />
                    ) : (
                      <RadioButtonUncheckedRoundedIcon />
                    )
                  }
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>

      <Grid item xs={0} md={8} lg={6}>
        <Typography
          variant="h4"
          color="white"
          sx={{
            position: "absolute",
            top: theme.spacing(2),
            left: theme.spacing(3),
          }}
        >
          reserve
        </Typography>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: theme.spacing(0, 6),
          }}
        >
          <Avatar
            sx={{
              bgcolor: theme.palette.primary.light,
              width: 56,
              height: 56,
              marginBottom: theme.spacing(4),
            }}
          >
            <SupervisedUserCircleRoundedIcon color="primary" fontSize="large" />
          </Avatar>

          {currentStep === 1 && (
            <>
              <Typography variant="h5" component="h1" gutterBottom>
                Choose Your Account Type
              </Typography>

              <OptionSelectionCard
                title="Personal"
                Icon={PermIdentityRoundedIcon}
                selected={
                  !userSelectedAccountType ? true : accountType === "user"
                }
                handleClick={() => chooseAccountType("user")}
              />
              <OptionSelectionCard
                title="Business"
                Icon={StorefrontRoundedIcon}
                selected={
                  !userSelectedAccountType
                    ? false
                    : accountType === "restaurant"
                }
                handleClick={() => chooseAccountType("restaurant")}
              />
            </>
          )}

          {currentStep === 2 && error && error.status === "error" && (
            <Alert
              severity="error"
              sx={{
                marginBottom: theme.spacing(2),
              }}
            >
              {error.message}
            </Alert>
          )}

          {currentStep === 2 && accountType === "restaurant" && (
            <>
              <Typography variant="h4" component="h1">
                Restaurant Sign Up
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
                sx={{
                  marginBottom: theme.spacing(4),
                }}
              >
                Hey, Enter your account information to continue. :)
              </Typography>

              <TextField
                label="Business Name"
                placeholder="e.g. Doe"
                name={fieldNames.business_name}
                value={formikBusinessUserSignup.values.business_name}
                onChange={formikBusinessUserSignup.handleChange}
                error={
                  formikBusinessUserSignup.touched.business_name &&
                  Boolean(formikBusinessUserSignup.errors.business_name)
                }
                helperText={
                  formikBusinessUserSignup.touched.business_name &&
                  formikBusinessUserSignup.errors.business_name
                }
              />

              <TextField
                label="Email"
                placeholder="e.g. johndoe@example.com"
                name={fieldNames.email}
                value={formikBusinessUserSignup.values.email}
                onChange={formikBusinessUserSignup.handleChange}
                error={
                  formikBusinessUserSignup.touched.email &&
                  Boolean(formikBusinessUserSignup.errors.email)
                }
                helperText={
                  formikBusinessUserSignup.touched.email &&
                  formikBusinessUserSignup.errors.email
                }
              />

              <TextField
                type="password"
                label="Password"
                placeholder="Please enter your password"
                name={fieldNames.password}
                value={formikBusinessUserSignup.values.password}
                onChange={formikBusinessUserSignup.handleChange}
                error={
                  formikBusinessUserSignup.touched.password &&
                  Boolean(formikBusinessUserSignup.errors.password)
                }
                helperText={
                  formikBusinessUserSignup.touched.password &&
                  formikBusinessUserSignup.errors.password
                }
              />

              <TextField
                type="password"
                label="Confirm Password"
                placeholder="Please enter your password again"
                name={fieldNames.confirm_password}
                value={formikBusinessUserSignup.values.confirm_password}
                onChange={formikBusinessUserSignup.handleChange}
                error={
                  formikBusinessUserSignup.touched.confirm_password &&
                  Boolean(formikBusinessUserSignup.errors.confirm_password)
                }
                helperText={
                  formikBusinessUserSignup.touched.confirm_password &&
                  formikBusinessUserSignup.errors.confirm_password
                }
              />
            </>
          )}

          {currentStep === 2 && accountType === "user" && (
            <>
              <Typography variant="h4" component="h1">
                Personal Sign Up
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
                sx={{
                  marginBottom: theme.spacing(4),
                }}
              >
                Hey, Enter your account information to sign up. :)
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  label="First Name"
                  placeholder="e.g. John"
                  name={fieldNames.first_name}
                  value={formikUserSignup.values.first_name}
                  onChange={formikUserSignup.handleChange}
                  error={
                    formikUserSignup.touched.first_name &&
                    Boolean(formikUserSignup.errors.first_name)
                  }
                  helperText={
                    formikUserSignup.touched.first_name &&
                    formikUserSignup.errors.first_name
                  }
                />

                <TextField
                  label="Last Name"
                  placeholder="e.g. Doe"
                  name={fieldNames.last_name}
                  value={formikUserSignup.values.last_name}
                  onChange={formikUserSignup.handleChange}
                  error={
                    formikUserSignup.touched.last_name &&
                    Boolean(formikUserSignup.errors.last_name)
                  }
                  helperText={
                    formikUserSignup.touched.last_name &&
                    formikUserSignup.errors.last_name
                  }
                />
              </Box>

              <TextField
                label="Email"
                placeholder="e.g. johndoe@example.com"
                name={fieldNames.email}
                value={formikUserSignup.values.email}
                onChange={formikUserSignup.handleChange}
                error={
                  formikUserSignup.touched.email &&
                  Boolean(formikUserSignup.errors.email)
                }
                helperText={
                  formikUserSignup.touched.email &&
                  formikUserSignup.errors.email
                }
              />

              <TextField
                type="password"
                label="Password"
                placeholder="Please enter your password"
                name={fieldNames.password}
                value={formikUserSignup.values.password}
                onChange={formikUserSignup.handleChange}
                error={
                  formikUserSignup.touched.password &&
                  Boolean(formikUserSignup.errors.password)
                }
                helperText={
                  formikUserSignup.touched.password &&
                  formikUserSignup.errors.password
                }
              />

              <TextField
                type="password"
                label="Confirm Password"
                placeholder="Please enter your password again"
                name={fieldNames.confirm_password}
                value={formikUserSignup.values.confirm_password}
                onChange={formikUserSignup.handleChange}
                error={
                  formikUserSignup.touched.confirm_password &&
                  Boolean(formikUserSignup.errors.confirm_password)
                }
                helperText={
                  formikUserSignup.touched.confirm_password &&
                  formikUserSignup.errors.confirm_password
                }
              />
            </>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              ...(currentStep === 1 && {
                justifyContent: "flex-end",
              }),
            }}
          >
            {currentStep !== 1 && (
              <Button
                variant="text"
                size="small"
                color="inherit"
                fullWidth={false}
                sx={{
                  marginTop: theme.spacing(2),
                }}
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Go Back
              </Button>
            )}
            {currentStep === 1 && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth={false}
                sx={{
                  marginTop: theme.spacing(2),
                }}
                onClick={() => setCurrentStep(2)}
              >
                Next Step
              </Button>
            )}
            {currentStep === 2 && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth={false}
                sx={{
                  marginTop: theme.spacing(2),
                }}
                onClick={() => {
                  if (accountType === "restaurant") {
                    formikBusinessUserSignup.handleSubmit();
                  } else {
                    formikUserSignup.handleSubmit();
                  }
                }}
              >
                Create Account
              </Button>
            )}
          </Box>

          <Typography
            variant="subtitle1"
            color="textSecondary"
            gutterBottom
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            Already have an account?{" "}
            <Link onClick={() => navigate(paths.login)}>Login</Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signup;

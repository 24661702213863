export const API_ENDPOINTS = {
  USER_LOGIN: "users/login",
  USER_SIGNUP: "users/signup",
  BUSINESS_USER_LOGIN: "business_users/login",
  BUSINESS_USER_SIGNUP: "business_users/signup",
  GET_ALL_GUESTS: "guest",
  GET_GUEST_BY_RESTAURANT: "guest/restaurant",
  ADD_GUEST: "guest",
  UPDATE_GUEST: "guest",
  DELETE_GUEST: "guest",
  GET_GUEST_RESERVATIONS: "guest/reservations",
  GET_SECTIONS_BY_RESTAURANT: "section/restaurant",
  GET_TABLES_BY_RESTAURANT: "table/restaurant",
  GET_AVAILABLE_TABLES_BY_RESTAURANT: "/available",
};

import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItemCard from "./MenuItemCard";
import NewMenuItemCard from "./NewMenuItemCard";
import { IItem } from "../../utils/constants/temp";
import { IMenuTabsProps } from "./MenuTabs";

interface ITabPanelProps extends IMenuTabsProps {
  index: number;
  value: number;
  menuItems: IItem[];
  sectionName: string;
}

const MenuItemsList: React.FC<ITabPanelProps> = (props) => {
  const { value, index, sectionName, menuItems, mode, ...other } = props;

  return (
    <Box
      sx={{
        p: 3,
        m: 2,
        minHeight: "65vh",
        width: "100%",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">{sectionName}</Typography>
          </Grid>

          {menuItems.map((menuItem, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <MenuItemCard
                MenuTabsProps={{
                  mode: mode,
                }}
                item={menuItem}
              />
            </Grid>
          ))}

          {mode === "edit" && (
            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ minHeight: "100%" }}>
              <NewMenuItemCard title={sectionName} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default MenuItemsList;

export const messages = {
  isRequired: "This field is required.",
  notValid: "Input value is not Valid",
  username: "Username must be 5 characters long.",
  notMatch: "Password doesn't match",
  password: "Password must be 8 charactors long",
};

export const fieldNames = {
  section_name: "section_name",
  username: "username",
  first_name: "first_name",
  last_name: "last_name",
  business_name: "business_name",
  data: "data",
  method: "method",
  email: "email",
  phone: "phone",
  mobile: "mobile",
  password: "password",
  confirm_password: "confirm_password",
};

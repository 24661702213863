import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";
import restaurantimage from "../../static/img/temp/restaurant.jpg";
import { Height } from "@mui/icons-material";
import RestaurantDetail from "../../components/restaurant-view/RestaurantBar";
import RestaurantBar from "../../components/restaurant-view/RestaurantBar";

const restaurantView: React.FC = () => {
  return (
    <>
    <Container maxWidth="xl">
      <Box
        sx={{
          width: "100%",
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://images.unsplash.com/photo-1551963831-b3b1ca40c98e"
          alt="restaurantimage"
          style={{
            width: "100%",
            height: 500,

            objectFit: "cover",
          }}
        />
      </Box>
      </Container>
      <Grid container>
        <Grid item xs={12}>
          <RestaurantBar />
        </Grid>
      </Grid>
    </>
  );
};

export default restaurantView;

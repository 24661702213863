import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TableBarRoundedIcon from "@mui/icons-material/TableBarRounded";
import FoodBankRoundedIcon from "@mui/icons-material/FoodBankRounded";
import RestaurantMenuRoundedIcon from "@mui/icons-material/RestaurantMenuRounded";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import { paths } from "./paths";

export const RestaurantSideBarPaths = [
  {
    name: "Dashboard",
    path: paths.dashboard,
    icon: <DashboardIcon />,
    enabled: false,
  },
  {
    name: "Reservation",
    path: paths.manage_reservations,
    icon: <FoodBankRoundedIcon />,
    enabled: true,
  },
  {
    name: "Orders",
    path: paths.new_order,
    icon: <RestaurantRoundedIcon />,
    enabled: true,
  },
  {
    name: "Tables",
    path: paths.tables,
    icon: <TableBarRoundedIcon />,
    enabled: true,
  },
  {
    name: "Menu",
    path: paths.menu,
    icon: <RestaurantMenuRoundedIcon />,
    enabled: true,
  },
  {
    name: "Guests",
    path: paths.guests,
    icon: <EmojiEmotionsRoundedIcon />,
    enabled: true,
  },
];

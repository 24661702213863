import moment from "moment";

export const getTimeFromMilliseconds = (time: number) => {
  let newTime = new Date(time);
  return new Intl.DateTimeFormat("default", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  }).format(newTime);
};

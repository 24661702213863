import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Skeleton from "@mui/material/Skeleton";
import NoImage from "../NoImage";

interface ImageProps {
  src?: string;
  loading?: boolean;
  alt?: string;
  height?: number;
  width?: number;
  maxWidth?: number;
}

const Image: React.FC<ImageProps> = (props) => {
  const { src, loading, alt, height, maxWidth } = props;
  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" height={height} width="100%" />
      ) : src ? (
        <Fade in={true}>
          <img
            src={src}
            alt={alt}
            style={{
              maxWidth,
            }}
          />
        </Fade>
      ) : (
        <Box
          sx={{
            height,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoImage />
        </Box>
      )}
    </>
  );
};

Image.defaultProps = {
  alt: "Image",
  loading: false,
  height: 250,
  maxWidth: 350,
};

export default Image;

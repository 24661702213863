import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import OrderCartItem from "./OrderCartItem";

const OrderCart: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        pb: 1,
        borderTop: 1,
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflowX: "hidden",
          borderBottom: "1px solid",
          borderColor: "divider",
          maxHeight: "60vh",
        }}
      >
        <List dense={true} sx={{ width: "100%", bgcolor: "background.paper" }}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
            <React.Fragment key={value}>
              <OrderCartItem />
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
          }}
        >
          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              ml: 2,
            }}
          >
            Subtotal:
          </Typography>

          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              mr: 2,
            }}
          >
            Rs. 0.00
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
          }}
        >
          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              ml: 2,
            }}
          >
            Tax:
          </Typography>

          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              mr: 2,
            }}
          >
            Rs. 0.00
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
          }}
        >
          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              ml: 2,
            }}
          >
            Service Charges:
          </Typography>

          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              mr: 2,
            }}
          >
            Rs. 0.00
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
          }}
        >
          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              ml: 2,
            }}
          >
            Total:
          </Typography>

          <Typography
            variant="body1"
            component="div"
            fontWeight="bold"
            sx={{
              mr: 2,
            }}
          >
            Rs. 0.00
          </Typography>
        </Box>

        <Box
          sx={{
            width: "80%",
          }}
        >
          <Button fullWidth variant="contained" size="large">
            Place Order
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderCart;

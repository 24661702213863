import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../common/Datagrid/CustomNoRowOverlay";
import { getGuestsByRestuarants } from "../../services/guests";
import { RootState } from "../../store";
import { IGuest } from "../../utils/interfaces/entities/guest.interface";

const columns: GridColDef[] = [
  { field: "_id", headerName: "ID", width: 70 },
  { field: "guest_name", headerName: "Guest Name", width: 130 },
  {
    field: "guest_email",
    headerName: "Email",
    width: 90,
  },
  {
    field: "guest_phone",
    headerName: "Phone",
    sortable: false,
    width: 160,
  },
  {
    field: "guest_gender",
    headerName: "Gender",
    sortable: false,
    width: 160,
  },
  {
    field: "guest_address",
    headerName: "Address",
    sortable: false,
    width: 160,
  },
];

const GuestsList: React.FC = () => {
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const businessUser = useSelector((state: RootState) => state.auth.user);

  const getGuests = async (id: string) => {
    setLoading(true);
    const response = await getGuestsByRestuarants(id);
    if (response.status === "success" && response.data) {
      setGuests(response.data);
    } else {
      setError(true);
      setErrorMessage(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (businessUser && businessUser.id) {
      getGuests(businessUser.id);
    }
  }, [businessUser]);

  return (
    <Box sx={{ width: "100%", height: 400 }}>
      <DataGrid
        rows={guests}
        columns={columns}
        loading={loading}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        pageSize={5}
        getRowId={(row: IGuest) => row._id}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </Box>
  );
};

export default GuestsList;

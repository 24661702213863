import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableListItem from "./TableListItem";
import AddReservationDialog from "./AddReservationModal/AddReservationDialog";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import AddGuestDialog from "./AddGuestModal/AddGuestDialog";

const buttons = [
  <Button key="one" fullWidth>
    Reservations
  </Button>,
  <Button key="two" fullWidth>
    Waiting
  </Button>,
];

const TablesList: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [openGuestModal, setOpenGuestModal] = React.useState<boolean>(false);

  const handleNewReservationClickOpen = () => {
    setOpen(true);
  };

  const handleNewReservationClose = () => {
    setOpen(false);
  };

  const handleNewGuestClickOpen = () => {
    setOpenGuestModal(true);
  };

  const handleNewGuestClose = () => {
    setOpenGuestModal(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <AppBar
          position="static"
          sx={{
            bgcolor: "background.paper",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h5" color="textSecondary">
                Tables List
              </Typography>
            </Box>
            <Box>
              <AddGuestDialog />
              <Button
                variant="contained"
                endIcon={<AddRoundedIcon />}
                onClick={handleNewReservationClickOpen}
              >
                New Reservation
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            m: 1,
            overflowY: "scroll",
            height: "79vh",
          }}
        >
          <Grid container spacing={2}>
            {Array.from({ length: 10 }).map((_, index) => (
              <Grid key={index} item xs={6}>
                <TableListItem />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <AddReservationDialog
        open={open}
        handleClose={() => handleNewReservationClose()}
      />
    </>
  );
};

export default TablesList;

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { DialogProps } from "@mui/material/Dialog";
import TableDetails from "./TableDetails";
import ReservationDetails from "./ReservationDetails";
import CircularProgress from "@mui/material/CircularProgress";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import ResponsiveDialog from "../../common/Dialog/ResponsiveDialog";
import AddGuestDialog from "../AddGuestModal/AddGuestDialog";

interface Film {
  title: string;
  year: number;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

interface IAddReservationDialogProps extends DialogProps {
  open: boolean;
  handleClose: () => void;
}

const AddReservationDialog: React.FC<IAddReservationDialogProps> = (props) => {
  const { open, handleClose } = props;

  const [loadGuests, setLoadGuests] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Film[]>([]);
  const loading = loadGuests && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...topFilms]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!loadGuests) {
      setOptions([]);
    }
  }, [loadGuests]);

  return (
    <ResponsiveDialog
      open={open}
      title="New Reservation"
      handleClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Create</Button>
        </>
      }
    >
      <Box
        sx={{
          minWidth: "100%",
          mb: 1,
        }}
      >
        <Autocomplete
          id="guest-search"
          fullWidth
          open={loadGuests}
          onOpen={() => {
            setLoadGuests(true);
          }}
          onClose={() => {
            setLoadGuests(false);
          }}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option.title}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Search for a guest"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    <InputAdornment position="end">
                      <AddGuestDialog />
                    </InputAdornment>
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Box>

      <Grid
        container
        spacing={1}
        sx={{
          borderTop: 1,
          borderColor: "divider",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <ReservationDetails />
        </Grid>
        <Grid item xs={12} md={6}>
          <TableDetails />
        </Grid>
      </Grid>
    </ResponsiveDialog>
  );
};

export default AddReservationDialog;

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topFilms = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
];

import React from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

interface IconButtonWithToolTipProps {
  children: React.ReactNode;
  tooltipText?: string;
  TooltipProps?: TooltipProps;
  IconButtonProps?: IconButtonProps;
  onClick: () => void;
}

const IconButtonWithToolTip: React.FC<IconButtonWithToolTipProps> = (props) => {
  const {
    children,
    tooltipText,
    TooltipProps,
    IconButtonProps,
    onClick,
  } = props;

  const tooltip = tooltipText ? true : false;

  return (
    <>
      {tooltip ? (
        <Tooltip
          {...{
            ...TooltipProps,
            title: tooltipText,
          }}
        >
          <IconButton onClick={onClick} {...IconButtonProps}>
            {children}
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton onClick={onClick} {...IconButtonProps}>
          {children}
        </IconButton>
      )}
    </>
  );
};

export default IconButtonWithToolTip;

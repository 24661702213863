import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { DialogProps } from "@mui/material/Dialog";
import ResponsiveDialog from "../../common/Dialog/ResponsiveDialog";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import { fieldNames } from "../../../utils/constants/formsConstants";

const AddGuestDialog: React.FC = () => {
  const [openGuestModal, setOpenGuestModal] = React.useState<boolean>(false);

  const handleNewGuestClickOpen = () => {
    setOpenGuestModal(true);
  };

  const handleNewGuestClose = () => {
    setOpenGuestModal(false);
  };

  return (
    <>
      <IconButton
        aria-label="add new guest"
        edge="end"
        onClick={handleNewGuestClickOpen}
        sx={{ mx: 1 }}
      >
        <PersonAddRoundedIcon />
      </IconButton>

      <ResponsiveDialog
        open={openGuestModal}
        title="New Guest"
        handleClose={handleNewGuestClose}
        actions={
          <>
            <Button onClick={handleNewGuestClose}>Cancel</Button>
            <Button onClick={handleNewGuestClose}>Add Guest</Button>
          </>
        }
      >
        <Box
          sx={{
            minWidth: "100%",
            mb: 1,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              label="First Name"
              placeholder="e.g. John"
              name={fieldNames.first_name}
            />
            <TextField
              label="Last Name"
              placeholder="e.g. Doe"
              name={fieldNames.last_name}
            />
          </Stack>

          <TextField
            fullWidth
            label="Email"
            placeholder="e.g. johndoe@example.com"
            name={fieldNames.email}
          />

          <TextField
            fullWidth
            label="Phone"
            placeholder="e.g. 123-456-7890"
            name={fieldNames.phone}
          />
        </Box>
      </ResponsiveDialog>
    </>
  );
};

export default AddGuestDialog;
